import { useParams } from 'react-router';
import React, { useContext, useEffect, useState } from 'react';
import { auth, db } from './firebase';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from './components/AuthProvider';
import './stylesheets/historydetails.css';
import { collection, onSnapshot } from 'firebase/firestore';

const HistoryDetails = () => {
const {currentUser} = useContext(AuthContext);
let navigate = useNavigate();
    
//If user not inlogged, navigates to sign in page
if(!currentUser || currentUser == null){
  window.location.href = "/signin";
  // navigate("/signin");
}

//Displays the scrollpaper and hides the pipe
if(document.querySelector(".navbar")){
    if(currentUser){
    if(document.querySelector(".signoutLink").classList.contains("hide")){
        document.querySelector(".signoutLink").classList.remove("hide");
    }
    }else{
        if(!document.querySelector(".signoutLink").classList.contains("hide")){
            document.querySelector(".signoutLink").classList.add("hide");
        }
    }

    if(!document.querySelector(".chestLink").classList.contains("hide")){
    document.querySelector(".chestLink").classList.add("hide");
    }
    if(document.querySelector(".pipeLink").classList.contains("hide")){
    document.querySelector(".pipeLink").classList.remove("hide");
    }
}
// const apiURL = "https://clickapi-hce5xudmta-ew.a.run.app";
    
let apiURL = "https://logbotai-backend.onrender.com/";
const authorizationToken = "gandalf";

const { selectedChest } = useParams();
const [data, setData] = useState([]);

// useEffect(()=>{
//   fetch(apiURL+"/conversations", {
//       method: "GET", // *GET, POST, PUT, DELETE, etc.
//       // mode: "cors", // no-cors, *cors, same-origin
//       headers: {
//         "Content-Type": "application/json", 
//         "Authorization": `${authorizationToken}`
//       }
//   }).then(response => response.json())
//   .then(data => {
//     console.log(data);
//     setData(data.filter(obj => obj.Hat === selectedChest));
//   });
// }, [])
const userUid = currentUser.uid;
  
//Updates the database; usersData
useEffect(()=>{
    onSnapshot(collection(db, "users", userUid, "sections"), (snapshot)=>{
      let allData = snapshot.docs.map((doc) => ({...doc.data(),id: doc.id}));
      let conversationsArray = allData.map(item => {
        return {
          conversation: item.conversations,
          id: item.id
        };
      });

      let convers = [];
      conversationsArray.forEach(arr => {
        arr.conversation.forEach( conversation => {
          if(conversation.Hat === selectedChest){
            convers.push({conversation, id: arr.id});
          }
        });
      });

      const groupedData = convers.reduce((acc, item) => {
        if (!acc[item.id]) {
          acc[item.id] = [];
        }
        acc[item.id].push(item.conversation);
        return acc;
      }, {});
      
      const result = Object.keys(groupedData).map(id => ({
        id,
        conversations: groupedData[id],
      }));
    setData(result);
    })
  },[]);

  return (
    <div className={`historyDetails-container ${selectedChest === 'green' ? 'green' : 'red'}`}>
      {data && (
        <div className='conversationsBoxes'>
          {data.map((obj, index) => {            
            let conversations = obj.conversations;
            let conversationArr = conversations.slice().reverse()

            let longTitle = false;
            if((conversationArr[0].Title).length > 70){
              longTitle = true;
            }
            return (
                <div className='conversationBox' 
                  key={obj.id}>
                  <h3 className='conversationTitle'>
                    <Link to={"/conversation/"+obj.id}>{longTitle ? (conversationArr[0].Title).slice(0, 70) + "..." : (conversationArr[0].Title)}</Link>
                  </h3> 
                    <p className='conversationDate'>{conversationArr[0].Date}</p>
                </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default HistoryDetails;