import React, { useContext, useEffect, useState } from 'react';
import { auth, db } from './firebase';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from './components/AuthProvider';
import './stylesheets/chests.css';
import { collection, onSnapshot } from 'firebase/firestore';

const History = () => {
  const {currentUser} = useContext(AuthContext);
    let navigate = useNavigate();
    
    //If user not inlogged, navigates to sign in page
    if(!currentUser){
      // navigate("/signin");
      window.location.href = "/signin";
      //Or to "You shall not pass" page??
    }

    //Displays the scrollpaper and hides the pipe
    if(document.querySelector(".navbar")){
        if(currentUser){
        if(document.querySelector(".signoutLink").classList.contains("hide")){
            document.querySelector(".signoutLink").classList.remove("hide");
        }
        }else{
            if(!document.querySelector(".signoutLink").classList.contains("hide")){
                document.querySelector(".signoutLink").classList.add("hide");
            }
        }

        if(!document.querySelector(".chestLink").classList.contains("hide")){
        document.querySelector(".chestLink").classList.add("hide");
        }
        if(document.querySelector(".pipeLink").classList.contains("hide")){
        document.querySelector(".pipeLink").classList.remove("hide");
        }
    }

    // const apiURL = "https://clickapi-hce5xudmta-ew.a.run.app";

    let apiURL = "https://logbotai-backend.onrender.com/";
    const authorizationToken = "gandalf";

    const [usersData, setUsersData] = useState(null);
    const [redConversations, setRedConversations] = useState([]);
    const [greenConversations, setGreenConversations] = useState([]);

    // const usersCollection = collection(db, "users");
    const userUid = currentUser.uid;
  
    //Updates the database; usersData
    useEffect(()=>{
        onSnapshot(collection(db, "users", userUid, "sections"), (snapshot)=>{
          
          let allData = snapshot.docs.map((doc) => doc.data());
          let conversations = allData.map(conversation => conversation.conversations);
          
          let redConvers = [];
          let greenConvers = [];
          conversations.forEach(arr => {
            arr.forEach( conversation => {
              conversation.Hat === "red" ? redConvers.push(conversation) : greenConvers.push(conversation);
            });
          });

          setRedConversations(redConvers);
          setGreenConversations(greenConvers);
        })
      },[]);

    // useEffect(()=>{
    //   fetch(apiURL+"/conversations", {
    //       method: "GET", // *GET, POST, PUT, DELETE, etc.
    //       // mode: "cors", // no-cors, *cors, same-origin
    //       headers: {
    //         "Content-Type": "application/json", 
    //         "Authorization": `${authorizationToken}`
    //       }
    //   }).then(response => response.json())
    //   .then(data => {
    //     console.log(data);
    //     setRedConversation(data.filter(obj => obj.Hat === "red"));
    //     setGreenConversation(data.filter(obj => obj.Hat === "green"));
    //   });
    // }, [])

  const [selectedGandalf, setSelectedGandalf] = useState("");

  const handleGandalfSelection = (color) => {
      setSelectedGandalf(color);
      console.log(color);
    }

    return (
      <div className='chest-container'>
        <div className='chestBoxes'>
                      <div className='imgDiv'>
                        <img src={require("./assets/redChest-selectBG.jpg")} 
                          alt='Gandalf The Red' 
                          className={`chestPic ${selectedGandalf === 'red' ? 'selected' : ''}`}
                        />
                        <Link to={"/History/red"} className='preNonSelectedDiv' onClick={() => handleGandalfSelection("red")}></Link>
                        <h2 className='chestTitle redChest'>The Red Chest</h2>
                        {redConversations.length !== 0  ? (
                          <div className='chestInfo'>
                            <p>
                              Advices from Gandalf The Red
                            </p>
                            <p>
                              Amount piping: {redConversations.length}
                            </p>
                          </div>
                          ) :
                          (
                          <div className='chestInfo'>
                            The Red Chest is empty!
                          </div>
                          )
                        }
                      </div>
                      <div className='imgDiv'>
                        <img src={require("./assets/greenChest-selectBG.jpg")} 
                            alt='Gandalf The Green' 
                            className={`chestPic ${selectedGandalf === 'green' ? 'selected' : ''}`}
                        />
                        <Link to={"/History/green"} className='preNonSelectedDiv' onClick={() => handleGandalfSelection("green")}></Link>
                        <h2 className='chestTitle greenChest'>The Green Chest</h2>
                        {greenConversations.length !== 0 ? (
                          <div className='chestInfo'>
                            <p>
                              Advices from Gandalf The Green
                            </p>
                            <p>
                              Amount piping: {greenConversations.length}
                            </p>
                          </div>
                          ) :
                          (
                          <div className='chestInfo'>
                            The Green Chest is empty!
                          </div>
                          )
                        }
                      </div>
        </div>
      </div>
  );
};

export default History;