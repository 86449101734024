// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDBEhHXdpYmwEfx4sGeyTf_ihSLQv4_K3w",
  authDomain: "elated-lotus-398211.firebaseapp.com",
  projectId: "elated-lotus-398211",
  storageBucket: "elated-lotus-398211.appspot.com",
  messagingSenderId: "380524912014",
  // appId: "1:380524912014:web:400d94def4a8bb7e1bd8df"
  appId: "1:380524912014:web:158578d76a1306e31bd8df"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);