import './stylesheets/notfound.css';
import {Link} from 'react-router-dom';

const Notfound = () => {
    return (
        <div className='notFound'>
            <div className='content-notfound'>
                <h2 className='title'>Sorry!</h2>
                <p>Page could not founded!</p>
                <Link to="/">To homepage</Link>
            </div>
        </div>
    );
}
 
export default Notfound;