import { signOut } from 'firebase/auth';
import React from 'react';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';

const SignOut = () => {
  let navigate = useNavigate();
    signOut(auth).then(()=>{
        navigate("/");
    }).catch(error=> console.log(error));
    
  return (
    <div>
      Sign Out successfully!
    </div>
  );
};

export default SignOut;