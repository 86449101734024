// @flow
import './stylesheets/navbar.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const signOut = <FontAwesomeIcon icon={faRightFromBracket} />
const profilIcon = <FontAwesomeIcon icon={faUser} />

const Navbar = () => {
  return (
    <nav className="navbar">
      <Link className ="navLink hide chestLink" to="/history"><img className='chest-nav' src={require("./assets/chest.png")}/></Link>
      <Link className ="navLink hide pipeLink" to="/select"><img className='pipe-nav' src={require("./assets/pipe.png")}/></Link>
      <Link className ="navLink title" to="/">Ask Gandalf!</Link>
      <Link className ="navLink hide signoutLink" to="/signOut"><img className='door-nav' src={require("./assets/door.png")}/></Link>
    </nav>
  );
};

export default Navbar;