import './stylesheets/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Signin from "./components/auth/Signin.js";
import Signup from "./components/auth/Signup.js";
import Notfound from './Notfound';
import React from 'react';
import Navbar from './Navbar';
import Ask from './Ask';
import PickGandalf from './PickGandalf';
import SignOut from './components/auth/SignOut';
import History from './History';
import AuthProvider from './components/AuthProvider';
import HistoryDetails from './HistoryDetails';
import Conversation from './Conversation';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
        <Navbar/>
          <Routes>
            <Route path="/" element={<Home />} /> 
            <Route path="/select" element={<PickGandalf />} /> 
            <Route path="/ask/:selectedGandalf" element={<Ask />} /> 
            <Route path="/signin" element={<Signin />} /> 
            <Route exact path="/history" element={<History />} /> 
            <Route path="/history/:selectedChest" element={<HistoryDetails />} />
            <Route path="/conversation/:conversationID" element={<Conversation />} />
            <Route path="/signup" element={<Signup />} /> 
            <Route path="/signout" element={<SignOut />} /> 
            <Route path="*" element={<Notfound />} /> 
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
