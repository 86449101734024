import { Link, useNavigate } from 'react-router-dom';
import './stylesheets/pickGandalf.css';
import React, { useContext, useEffect, useState } from 'react';
import { auth } from './firebase';
import { AuthContext } from './components/AuthProvider';
import saveToQuerry from './components/utils';
import getFromQuerry from './components/utils';

const PickGandalf = () => {
    const {currentUser} = useContext(AuthContext);
    let navigate = useNavigate();
    if(!currentUser){
        navigate("/signin");
    }
  
    //Displays the scrollpaper and hides the pipe
    if(document.querySelector(".navbar")){
        if(currentUser){
            if(document.querySelector(".signoutLink").classList.contains("hide")){
                document.querySelector(".signoutLink").classList.remove("hide");
            }
        }else{
            if(!document.querySelector(".signoutLink").classList.contains("hide")){
                document.querySelector(".signoutLink").classList.add("hide");
            }
        }
        
        if(document.querySelector(".chestLink").classList.contains("hide")){
            document.querySelector(".chestLink").classList.remove("hide");
        }
        if(!document.querySelector(".pipeLink").classList.contains("hide")){
            document.querySelector(".pipeLink").classList.add("hide");
        }
    }
    
    const [selectedGandalf, setSelectedGandalf] = useState("");
    const [gandalfInfo, setGandalfInfo] = useState({
      name: 'Pick a Gandalf',
      description: 'Please select a Gandalf to learn more about him.',
      color: null,
    });

    // useEffect(()=>{
    //     setSelectedGandalf(selectedGandalf);
    // },[selectedGandalf])

    const handleGandalfHover = (color) => {
        
        const redInfo = {
            name: 'Gandalf The Red',
            description: 'Brings a critical and analytical perspective to your idea analysis. He will thoroughly examine your idea, pointing out potential problems, risks, and weaknesses, helping you refine it for success.',
            color: 'red',
        }
        const greenInfo = {
            name: 'Gandalf The Green',
            description: 'Approaches idea analysis with creativity and fresh perspectives. He will encourage you to think outside the box, generate innovative ideas, and explore alternative solutions to enhance your idea.',
            color: 'green',
        }

        if(selectedGandalf){
            if(color != null){
                color === "red" ? setGandalfInfo(redInfo) : setGandalfInfo(greenInfo);
            }else{
                selectedGandalf === "red" ? setGandalfInfo(redInfo) : setGandalfInfo(greenInfo);
            }
        }else{
            if(color != null){
                color === "red" ? setGandalfInfo(redInfo) : setGandalfInfo(greenInfo);
            }else{
                const info = {
                    name: 'Pick a Gandalf',
                    description: 'Please select a Gandalf to learn more about him.',
                    color: null,
                }
                setGandalfInfo(info);
            }
        }
    }

    const handleGandalfSelection = (color) => {
        setSelectedGandalf(color);

        const info = color === 'red'
          ? {
            name: 'Gandalf The Red',
            description: 'Brings a critical and analytical perspective to your idea analysis. He will thoroughly examine your idea, pointing out potential problems, risks, and weaknesses, helping you refine it for success.',
            color: 'red',
          }
          : {
            name: 'Gandalf The Green',
            description: 'Approaches idea analysis with creativity and fresh perspectives. He will encourage you to think outside the box, generate innovative ideas, and explore alternative solutions to enhance your idea.',
            color: 'green',
          };

        let bodysClasslist = document.body.classList;
        if(bodysClasslist.length !== 0){
            bodysClasslist.remove(bodysClasslist.item(0));
        }
        
        color === "red" ? bodysClasslist.add("redBG") : bodysClasslist.add("greenBG");
        setGandalfInfo(info);
        
        //Saves data to bigquerry
        // saveToQuerry(info.color);

        // If want to save attributes
        // saveToQuerry(info.color, {"name": info.name});

        //Gets all data from bigquerry
        // getFromQuerry("*");
        
        //To get a specific data from bigquerry
        // getFromQuerry("attributes");

    }

    return (
        <div className='select-container'>
            <div className='infoBox'>
                <img src={require("./assets/gandalfsstaff2.png")} 
                    alt='Gandalf´s Staff' 
                    className='gandalfsstaff'>
                </img>
                <div className='selectedInfo'>
                    <h2>{gandalfInfo.name}</h2>
                    <p className='infoSelected'>
                        {gandalfInfo.description}
                    </p>
                    {selectedGandalf && (
                        <div className='cauldronsDiv'>
                            <Link to={`/ask/${selectedGandalf}`}>
                                <img className='cauldron' src={require(`./assets/${selectedGandalf}_cauldron.png`)} />
                            </Link>
                        </div>
                    )}
                </div>
            </div>
            <div className='picBoxes'>
                    <img src={require("./assets/GandalfTheRed.png")} 
                        alt='Gandalf The Red' 
                        onMouseOver={(e) => {handleGandalfHover("red")}}
                        onMouseLeave={()=>{handleGandalfHover(null)}} 
                        onClick={() => handleGandalfSelection("red")} 
                        className={`pic redG ${selectedGandalf === 'red' ? 'selected' : ''}`}
                        />
                    <img src={require("./assets/GandalfTheGreen.png")} 
                        alt='Gandalf The Green' 
                        onMouseOver={(e) => {handleGandalfHover("green")}} 
                        onMouseLeave={()=>{handleGandalfHover(null)}}
                        onClick={() => handleGandalfSelection("green")} 
                        className={`pic greenG ${selectedGandalf === 'green' ? 'selected' : ''}`}
                        />
            </div>
            <div className={`nameBox ${gandalfInfo.color}`}>
                <h1 className='typeOfGandalf'>{gandalfInfo.name}</h1>
            </div>
        </div>
    );
};

export default PickGandalf;