import '../../stylesheets/signup.css';
import { Link, useNavigate } from "react-router-dom";
import { useRef, useState, useEffect, useContext} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {auth} from "../../firebase.js";
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { AuthContext } from '../AuthProvider';

const NAME_REGEX = /^[a-zA-Z]{4,15}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@(hotmail\.com|gmail\.com)$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Signup = () => {
    const {currentUser} = useContext(AuthContext);

    let navigate = useNavigate();
    if(currentUser){
        // navigate("/")
        window.location.href = "/";
    }

    const userRef = useRef();
    const errRef = useRef();

    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [validName, setValidName] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);
    
    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);
 
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    // useEffect(()=>{
    //     userRef.current.focus();
    // },[])
    
    useEffect(()=>{
        const result = NAME_REGEX.test(name);
        setValidName(result);
    },[name])

    useEffect(()=>{
        const result = EMAIL_REGEX.test(email);
        setValidEmail(result);
    },[email])

    useEffect(()=>{
        const result = PWD_REGEX.test(pwd);
        setValidPwd(result);
        const match = pwd == matchPwd;
        setValidMatch(match);
    },[pwd, matchPwd])

    useEffect(()=>{
        setErrMsg('');
    }, [name,email,pwd,matchPwd]);

    const handleSubmit = (e)=>{
        e.preventDefault();
        setLoading(true);

        createUserWithEmailAndPassword(auth, email, pwd)
            .then((userCredential) => {
                const user = userCredential.user;
                navigate("/");
                return updateProfile(user, {
                  displayName: name
                });
            })
            .catch((error) => {
                // setErrMsg(error.code);
                setErrMsg("E-mail already in use!");
                setLoading(false);
            })
    }

    return (
        <section className={loading ? "auth-container loading" : "auth-container"}>
            {loading ? <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>:
            <> 
                <h2>Join the Fellowship</h2>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <form id='newUserForm' onSubmit={handleSubmit}>
                <label htmlFor="name">
                        Name:
                        <span className={validName ? "valid" : "hide"}>
                            <FontAwesomeIcon icon = {faCheck}/>
                        </span>
                        <span className={validName || !email ? "hide" : "invalid"}>
                            <FontAwesomeIcon icon = {faTimes}/>
                        </span>
                    </label>
                    <input 
                        type="text" 
                        id="name"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setName(e.target.value)}
                        required
                        aria-describedby='namenote'
                        onFocus={()=> setNameFocus(true)}
                        onBlur={()=>setNameFocus(false)}
                    />
                    <p id="namenote" className={nameFocus && name && !validName ? "instructions" : "offscreen"}>
                        <FontAwesomeIcon icon = {faInfoCircle}/>
                        4 to 15 characters.
                    </p>
                    <label htmlFor="email">
                        E-mail:
                        <span className={validEmail ? "valid" : "hide"}>
                            <FontAwesomeIcon icon = {faCheck}/>
                        </span>
                        <span className={validEmail || !email ? "hide" : "invalid"}>
                            <FontAwesomeIcon icon = {faTimes}/>
                        </span>
                    </label>
                    <input 
                        type="text" 
                        id="email"
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        aria-invalid = {validEmail ? "false" : "true"}
                        aria-describedby="uidnote"
                        onFocus={()=> setEmailFocus(true)}
                        onBlur={()=>setEmailFocus(false)}
                    />
                    <p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                        <FontAwesomeIcon icon = {faInfoCircle}/>
                        4 to 24 characters. <br />
                        Letters, numbers, underscores, hypens allowed. <br />
                        Must have an "@hotmail.com" or "@gmail.com"
                    </p>
                    <label htmlFor="password">
                        Password:
                        <span className={validPwd ? "valid" : "hide"}>
                            <FontAwesomeIcon icon = {faCheck}/>
                        </span>
                        <span className={validPwd || !pwd ? "hide" : "invalid"}>
                            <FontAwesomeIcon icon = {faTimes}/>
                        </span>
                    </label>
                    <input 
                        type="password" 
                        id="password"
                        onChange={(e) => setPwd(e.target.value)}
                        required
                        aria-invalid = {validPwd ? "false" : "true"}
                        aria-describedby="pwdnote"
                        onFocus={()=> setPwdFocus(true)}
                        onBlur={()=>setPwdFocus(false)}
                    />
                    <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                        <FontAwesomeIcon icon = {faInfoCircle}/>
                        8 to 24 characters. <br />
                        Must include uppercase and lowercase letters, a number and a special character. <br />
                        Allowed special characters: <span aria-label="exclamation mark">!</span>
                        <span aria-label="at symbol">@</span><span aria-label="hashtag">#</span>
                        <span aria-label="dollar sign">$</span><span aria-label="percent">%</span>
                    </p>
                    <label htmlFor="confirm_pwd">
                        Confirm Password:
                        <span className={validMatch && matchPwd ? "valid" : "hide"}>
                            <FontAwesomeIcon icon = {faCheck}/>
                        </span>
                        <span className={validMatch || !matchPwd ? "hide" : "invalid"}>
                            <FontAwesomeIcon icon = {faTimes}/>
                        </span>
                    </label>
                    <input 
                        type="password" 
                        id="confirm_pwd"
                        onChange={(e) => setMatchPwd(e.target.value)}
                        required
                        aria-invalid = {validMatch ? "false" : "true"}
                        aria-describedby="confirmnote"
                        onFocus={()=> setMatchFocus(true)}
                        onBlur={()=> setMatchFocus(false)}
                    />
                    <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                        <FontAwesomeIcon icon = {faInfoCircle}/>
                        Passwords must be matched.
                    </p>
                    <button disabled= {!validName || !validEmail || !validPwd || !validMatch ? true : false}>Join</button>
                </form>
                <p>Already member? <Link to="/signin">Unlock!</Link></p>
            </>   
            }
        </section>
    );
}
 
export default Signup;