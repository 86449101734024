import '../../stylesheets/signin.css';
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRef, useState, useEffect} from "react";
import { signInWithEmailAndPassword } from 'firebase/auth';
import {auth} from "../../firebase.js";
import { AuthContext } from '../AuthProvider';

const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@(hotmail\.com|gmail\.com)$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Signin = () => {
    const {currentUser} = useContext(AuthContext);
    let navigate = useNavigate();
 
    let apiURL = "https://logbotai-backend.onrender.com/";
    // apiURL = "http://localhost:8080/";

    // Waking up the render.com server
    useEffect(() => {
        const wakeUpServer = async () => {

            console.log("WakeUpServer function has been called!");
            try {
                fetch(apiURL+"wakeup")
                .then(response => {
                    return response.ok;
                }
                ).then(data => {
                    console.log(data);
                });
         
                // const response = await axios.get(`${apiURL}wakeup`);
                // console.log(response.data);
            } catch (err) {
                console.error("Error waking up the server:", err);
            }
        };

        wakeUpServer(); // Call the function

    }, []); // Empty dependency array to run once on mount


    if(currentUser){
        // navigate("/");
        window.location.href = "/";
    }
    const userRef = useRef();
    const errRef = useRef();

    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);
    
    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);
 
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    // useEffect(()=>{
    //     userRef.current.focus();
    // },[])
      
    useEffect(()=>{
        const result = EMAIL_REGEX.test(email);
        setValidEmail(result);
    },[email])

    useEffect(()=>{
        const result = PWD_REGEX.test(pwd);
        setValidPwd(result);
    },[pwd])

    useEffect(()=>{
        setErrMsg('');
    }, [email,pwd]);

    const handleSubmit = (e)=>{
        e.preventDefault();
        setLoading(true);
        signInWithEmailAndPassword(auth, email, pwd)
        .then((userCredential) => {
            // console.log(userCredential);
            navigate("/");
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
            setErrMsg("Sorry! Not in the fellowship!");
        })
    }

    return (
    <section className={loading ? "auth-container loading" : "auth-container"}>
        {loading ? <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>: 
         <>    
            <h2>Unlock Gandalf's Wisdom</h2>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">
                    E-mail:
                </label>
                <input 
                    type="text" 
                    id="email"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    aria-invalid = {validEmail ? "false" : "true"}
                    onFocus={()=> setEmailFocus(true)}
                    onBlur={()=>setEmailFocus(false)}
                />
                <label htmlFor="password">
                    Password:
                </label>
                <input 
                    type="password" 
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    required
                    aria-invalid = {validPwd ? "false" : "true"}
                    onFocus={()=> setPwdFocus(true)}
                    onBlur={()=>setPwdFocus(false)}
                />            
                <button disabled= {!validEmail || !validPwd ? true : false}>Sign in</button>
            </form>
            <p>Not a member yet? <Link to="/signup">Join the fellowship!</Link></p>
         </>
        }
    </section>
    );
};

export default Signin;