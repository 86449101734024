import { useParams } from 'react-router';
import React, { useContext, useEffect, useState } from 'react';
import { auth, db } from './firebase';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from './components/AuthProvider';
import './stylesheets/conversations.css';
import { collection, onSnapshot } from 'firebase/firestore';

const Conversation = () => {
    const {currentUser} = useContext(AuthContext);
    let navigate = useNavigate();
        
    //If user not inlogged, navigates to sign in page
    if(!currentUser){
        window.location.href = "/signin";
        // navigate("/signin");
    }

    //Displays the scrollpaper and hides the pipe
    if(document.querySelector(".navbar")){
        if(currentUser){
        if(document.querySelector(".signoutLink").classList.contains("hide")){
            document.querySelector(".signoutLink").classList.remove("hide");
        }
        }else{
            if(!document.querySelector(".signoutLink").classList.contains("hide")){
                document.querySelector(".signoutLink").classList.add("hide");
            }
        }

        if(!document.querySelector(".chestLink").classList.contains("hide")){
        document.querySelector(".chestLink").classList.add("hide");
        }
        if(document.querySelector(".pipeLink").classList.contains("hide")){
        document.querySelector(".pipeLink").classList.remove("hide");
        }
    }
    // const apiURL = "https://clickapi-hce5xudmta-ew.a.run.app";
    let apiURL = "https://logbotai-backend.onrender.com/";
    const authorizationToken = "gandalf";

    const { conversationID } = useParams();
    const [selectedConversation, setSelectedConversation] = useState([]);

    const userUid = currentUser?.uid;
    
    //Updates the database; usersData
    useEffect(()=>{
        if(currentUser){
            onSnapshot(collection(db, "users", userUid, "sections"), (snapshot)=>{
                let allData = snapshot.docs.map((doc) => ({...doc.data(),id: doc.id}));
                let conversationSelected = allData.filter(conversation => conversation.id === conversationID);
                setSelectedConversation(conversationSelected);
            })
        }
    },[]);

    let selectedChest = selectedConversation[0]?.conversations[0].Hat;

    return (
        <div  className={`historyDetails-container ${selectedChest === 'green' ? 'green' : 'red'}`}>
            <div className='conversBoxes'>
                {selectedConversation.map(obj => {
                    let conversationArr = obj.conversations;
                    return (
                        <div key={obj.id}>
                            {conversationArr.map(conversation => (
                                <div className='conversBox' key={`${obj.id + crypto.randomUUID()}`}>
                                    <p className='conversTitle'><span className='bold'>{currentUser?.displayName}: </span>{conversation.Title}</p> 
                                    <p className='conversDate'>{conversation.Date}</p>
                                    <p className='conversResponse'><span className='bold'>Gandalf The {selectedChest === 'green' ? 'Green' : 'Red'}:</span> {conversation.Response}</p>
                                </div>
                            ))}
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default Conversation;