import { Link } from 'react-router-dom';
import './stylesheets/home.css';
import React, { useEffect, useContext } from 'react';
import {auth} from "./firebase.js";
import { AuthContext } from './components/AuthProvider.js';

const Home = () => {
    const {currentUser} = useContext(AuthContext);

    if(document.querySelector(".navbar")){
        if(currentUser){
            if(document.querySelector(".signoutLink").classList.contains("hide")){
                document.querySelector(".signoutLink").classList.remove("hide");
            }
        }else{
            if(!document.querySelector(".signoutLink").classList.contains("hide")){
                document.querySelector(".signoutLink").classList.add("hide");
            }
        }
        if(!document.querySelector(".chestLink").classList.contains("hide")){
            document.querySelector(".chestLink").classList.add("hide");
        }
        if(!document.querySelector(".pipeLink").classList.contains("hide")){
            document.querySelector(".pipeLink").classList.add("hide");
        }
      
    }

    return (
        <div className='homepage-container'>
            {currentUser ? <>
                <h1>Welcome {currentUser.displayName}!</h1>
                <p>We're delighted to have you here. How can Gandalf assist you today?</p>
                <div className='home-options'>
                    <Link to={"/history"} className="home-option"> 
                    <img className='chest' src={require("./assets/chest.png")}/>
                    <p>To Advices</p>
                    </Link>
                    <Link to={"/select"} className="home-option">
                        {/* To Cauldron - icon */}
                        <img className='pipe' src={require("./assets/pipe.png")}/>
                        <p>Let´s Pipe</p>
                    </Link>
                </div>
            </> : 
            <>
                <h1>Welcome to Gandalf's Wisdom!</h1>
                <p>Step into the mystical world of Gandalf the King, the legendary wizard of Middle-earth. <br></br>Here, you can seek Gandalf's wise counsel and share your ideas with a guardian of ancient knowledge.</p>
                <p>Gandalf values the voices of his allies, those who are committed to the greater good and the pursuit of wisdom. <br></br> Are you one of them?</p>
                <Link to={"/signin"} className="proveBtn"><span>Prove your alliance!</span></Link>
            </>
            }
        </div>
    );
};

export default Home;